@layer base {
  .main--loading {
  cursor: not-allowed;
}

.main--loading a,
.main--loading button {
  pointer-events: none;
}

}