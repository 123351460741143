@layer base {
  /* Layout grid: parent */
.layout {
  --local-row-gap: var(--sk-space-8);
  --local-column-gap: var(--sk-space-8);
  --local-horizontal-margin: var(--sk-space-16);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-right: var(--local-horizontal-margin);
  padding-left: var(--local-horizontal-margin);
  gap: var(--local-row-gap) var(--local-column-gap);
}

.layout.variant_4cols,
.layout.variant_3cols {
  grid-template-columns: 100%;
}

@media (--sk-medium-viewport) {
  .layout {
    --local-row-gap: var(--sk-space-16);
    --local-column-gap: var(--sk-space-16);
    --local-horizontal-margin: var(--sk-space-40);
    grid-template-columns: repeat(12, 1fr);
    max-width: calc(12 * var(--local-column-width) + 11 * var(--local-column-gap));
  }

  .layout.variant_4cols,
  .layout.variant_3cols {
    grid-template-columns: repeat(2, minmax(calc(50% - var(--local-column-gap)), 1fr));
  }
}

@media (--sk-large-viewport) {
  .layout {
    --local-column-width: 88px;
    --local-horizontal-margin: 0;
    position: relative;
    grid-template-columns: repeat(12, var(--local-column-width));
    width: 100%;
    max-width: calc(12 * var(--local-column-width) + 11 * var(--local-column-gap));
    margin-right: auto;
    margin-left: auto;
  }

  .layout.variant_4cols {
    grid-template-columns: repeat(4, minmax(calc(25% - var(--local-column-gap)), 1fr));
  }

  .layout.variant_3cols {
    grid-template-columns: repeat(3, minmax(calc(33.33% - var(--local-column-gap)), 1fr));
  }
}

/* Layout grid: children */
.layout > .layout__child___full,
.layout > .layout__child___large,
.layout > .layout__child___medium {
  grid-column: 1 / span 4;
}

@media (--sk-medium-viewport) {
  .layout > .layout__child___full {
    grid-column: 1 / span 12;
  }

  .layout > .layout__child___large {
    grid-column: 2 / span 10;
  }

  .layout > .layout__child___medium {
    grid-column: 3 / span 8;
  }
}

@media (--sk-large-viewport) {
  .layout > .layout__child___large {
    grid-column: 3 / span 8;
  }

  .layout > .layout__child___medium {
    grid-column: 4 / span 6;
  }
}

/* Layout grid: custom children */
.layout > .layout__child___custom {
  grid-column: 1 / span 4;
}

@media (--sk-medium-viewport) {
  .layout > .layout__child___custom {
    grid-column: var(--layout-child-from) / var(--layout-child-to);
  }
}

}