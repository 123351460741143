@layer base {
  .main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-16);
  padding: var(--sk-space-24);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
}

.button {
  flex-shrink: 0;
}

}