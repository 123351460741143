@layer base {
  .instructionsTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sk-space-8);
  margin-bottom: var(--sk-space-16);
}

.instructionsTitle p {
  line-break: anywhere; /* Long character chains like links break the responsive layout */
}

.logo {
  object-fit: cover;
}

.divider {
  margin: var(--sk-space-32) 0;
}

.fields + .fields {
  margin-top: var(--sk-space-16);
}

}