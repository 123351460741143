@layer base {
  /* HEADER styles */

.section {
  padding: var(--sk-space-16);
  border-radius: var(--sk-space-16);
  gap: var(--sk-space-8);
  background-color: var(--sk-color-grey-050);
}

.request {
  margin-top: var(--sk-space-24);
  margin-bottom: var(--sk-space-24);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.badgesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.date {
  align-self: flex-start;
  margin-bottom: var(--sk-space-8);
}

.duration {
  align-self: flex-start;
}

.appointmentTitle {
  margin-top: var(--sk-space-8);
}

.calendarPictoContainer {
  display: none;
}

.callout {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .request {
    display: none;
  }

  .section {
    margin-top: 92px;
    padding: var(--sk-space-40);
    border-radius: var(--sk-space-40);
    gap: var(--sk-space-10);
  }

  .badgesContainer {
    flex-direction: row;
  }

  .date {
    margin-right: var(--sk-space-12);
    margin-bottom: 0;
  }

  .appointmentTitle {
    margin-top: var(--sk-space-16);
  }

  .callout {
    margin-top: var(--sk-space-24);
    margin-bottom: var(--sk-space-40);
  }
}

@media (--sk-medium-viewport) {
  .section {
    margin-top: 92px;
    padding: var(--sk-space-40);
    border-radius: var(--sk-space-40);
    gap: var(--sk-space-10);
  }

  .date {
    margin-right: var(--sk-space-12);
    margin-bottom: 0;
  }

  .appointmentTitle {
    margin-top: var(--sk-space-16);
  }

  .callout {
    margin-top: var(--sk-space-24);
    margin-bottom: var(--sk-space-40);
  }
}

@media (--sk-large-viewport) {
  .wideScreen {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }

  .calendarPictoContainer {
    display: block;
    position: absolute;
    top: -32px;
    right: -80px;
  }

  .calendarPicto {
    object-fit: cover;
    rotate: -32.04deg;
  }
}

/* BUTTONS styles */

.buttons {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .buttons {
    flex-direction: row;
    justify-content: center;
    gap: var(--sk-space-16);
    padding-top: var(--sk-space-24);
    padding-bottom: var(--sk-space-24);
  }
}

}