@layer base {
  .main {
  margin-bottom: var(--sk-space-24);
}

.main,
.secondary {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-16) var(--sk-space-8);
}

}