@layer base {
  .main,
.main:link,
.main:visited {
  display: block;
  padding: var(--sk-space-12) var(--sk-space-16);
  border: var(--sk-space-2) solid var(--sk-color-black);
  border-radius: var(--sk-radius-8);
  color: inherit;
  text-decoration: none;
}

.main:is(a):hover {
  background: var(--sk-color-dark-10);
}

.main:is(a):focus-visible {
  outline: var(--sk-space-2) solid var(--sk-action-button-color-border-secondary-default-light);
  outline-offset: calc(-1 * var(--sk-space-2));
  background-color: var(--sk-action-button-color-background-secondary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-secondary-focus-light);
}

.main:is(a):active {
  background: var(--sk-color-dark-20);
}

}