@layer base {
  .title {
  margin: var(--sk-space-24) 0 var(--sk-space-8);
}

.section {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: var(--sk-space-24);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-4);
}

}