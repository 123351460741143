@layer base {
  .main__bodyText {
  font-size: var(--sk-typography-body-1-regular-font-size);
  font-style: normal;
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.main__dropdownContent {
  padding: var(--sk-space-8) var(--sk-space-16);
  color: var(--sk-color-grey-500);
  font-family: var(--sk-font-families-poppins);

  text-align: center;
}

.main__dropdownItem {
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border: none;
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-poppins);
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.main__dropdownItem:hover,
.main__dropdownItem:focus,
.main__dropdownItem:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
  cursor: pointer;
}

.main__dropdownAbroadOption {
  display: flex;
  gap: 8px;
  align-items: center;
}

.main__error {
  color: var(--sk-color-error-500);
}

.main__abroadIcon {
  width: var(--sk-space-20);
  height: var(--sk-space-20);
}

}