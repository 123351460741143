@layer base {
  .avatar {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.avatar img {
  object-fit: contain;
}

}