@layer base {
  .main {
  --sk-data-display-card-body-content-gap: var(--sk-space-8);
  --sk-data-display-card-body-margin-right: var(--sk-space-64);
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  position: relative;
  padding: var(--sk-space-24);

  transition: border 100ms ease-out;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
}

.main a {
  outline: none;
  color: inherit;
  text-decoration: none;
}

.main a::after {
  content: "";
  position: absolute;
  /* place it above the seen filter */
  z-index: var(--z-up-in-the-current-stacking-context);
  inset: 0;
}

.main a:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-color-grey-200) 2px solid;
}

.main a:focus-visible::after {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);

  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

}