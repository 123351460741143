@layer base {
  .aside {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
}

.aside___closing {
  /*
    The JS relies on the `animationend` event to close the Application Flow.
    So, we need to define a no-op animation for small and medium screens.
  */
  animation: no-op forwards;
}

.aside___similarJobads {
  display: none;
}

@keyframes no-op {
  0%,
  100% {
    transform: none;
  }
}

@media (--sk-large-viewport) {
  .aside {
    --application-flow-width: 31vw;
    content: "displayedAsPanel"; /* used on javascript side */
    position: absolute;
    z-index: var(--z-up-in-the-current-stacking-context);
    width: var(--application-flow-width);
    max-width: 560px;
    height: 100%;
    transform: translateX(calc(-1 * var(--sk-space-24)));
    animation: slide-in-from-right 0.5s;
    inset: 0 0 0 auto;
  }

  .aside___similarJobads {
    display: block;
  }

  .aside___closing {
    animation: slide-out-from-right 0.5s;
    opacity: 0;
  }

  @keyframes slide-in-from-right {
    0% {
      transform: translateX(0);
      opacity: 0;
    }

    33% {
      transform: translateX(0);
      opacity: 0;
    }

    100% {
      transform: translateX(calc(-1 * var(--sk-space-24)));
      opacity: 1;
    }
  }

  @keyframes slide-out-from-right {
    0% {
      transform: translateX(calc(-1 * var(--sk-space-24)));
      opacity: 1;
    }

    33% {
      transform: translateX(0);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 0;
    }
  }
}

}