@layer base {
  .title,
.subtitle {
  margin-bottom: var(--sk-space-24);
}

.title:has(+ .subtitle),
.list:has(+ .subtitle) {
  margin-bottom: var(--sk-space-40);
}

.list {
  --local-column-count: 1;
  display: grid;
  grid-template-columns: repeat(var(--local-column-count), 1fr);
  margin: 0;
  padding: 0;

  list-style-type: none;
  gap: var(--sk-space-16);
}

/* 480px */

@media (width > 30rem) {
  .list {
    --local-column-count: 2;
  }
}

.linkCard {
  display: flex;
  box-sizing: border-box;
  align-content: space-between;
  align-items: center;
  height: 100%;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-16);
}

.link span {
  flex: 1;
}

}