@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.title {
  margin-top: var(--sk-space-24);
}

}